import Layout from "components/Layout"
import React from "react"
import SEO from "components/seo"
import Shops from "views/Shops"

const ShopsPage = () => {
  return (
    <Layout>
      <SEO
        title="Prodejny"
        description="Vypínače Schneider Electric najdete v obchodech po celé republice. Máme pro Vás mapu, která Vám pomůže najít ten nejbližší!"
      />
      <Shops />
    </Layout>
  )
}

export default ShopsPage
