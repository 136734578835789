import React from 'react'
import MapWrapper from '../components/Map'
import ShopsHeader from '../components/Shops/ShopsHeader'
import ShopsFooter from '../components/Shops/ShopsFooter'
import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

const shopHeaderPinIcon = require('../images/shopsHeaderPin.png')
const socketPlaceholderIcon = require('../images/shopsFooterSocket.png')

// const GET_SHOPS_QUERY = graphql`
//   query GetShops {
//     cms {
//       pois(where: { Type: { Name: "Prodejny" } }, sort: "Name:ASC") {
//         id
//         Coordinates {
//           id
//           Longtitude
//           Latitude
//         }
//         Name
//         Address
//         PostalCode
//         City
//         Phone
//         Email
//         Web
//         Type {
//           Name
//         }
//       }
//     }
//   }
// `

const SHOPS_QUERY = gql`
  query {
    pois(where: { Type: { Name: "Prodejny" } }, sort: "Name:ASC") {
      id
      Coordinates {
        id
        Longtitude
        Latitude
      }
      Name
      Address
      PostalCode
      City
      Phone
      Email
      Web
      Type {
        Name
      }
    }
  }
`

const Shops: React.ComponentType = () => {
  const { t } = useTranslation()

  // This is a fix of not fetching updated data from CMS until FE gets build again. This should be fixed via webhooks in circleCI and CMS
  const { data } = useQuery(SHOPS_QUERY)
  // const { cms } = useStaticQuery(GET_SHOPS_QUERY)

  return (
    <div>
      <ShopsHeader
        title={t('SHOPS_TITLE')}
        icon={shopHeaderPinIcon}
        retinaIcon={true}
      />
      {data && data['pois'] && (
        <MapWrapper
          placeholder={t('SEARCH_AUTOCOMPLETE_POI_SHOPS')}
          type="Prodejny"
          deliveryServiceData={data['pois']}
        />
      )}

      <ShopsFooter
        title={t('SHOPS_FOOTER_TITLE')}
        description={t('SHOPS_FOOTER_DESCRIPTION')}
        icon={socketPlaceholderIcon}
        buttonText={t('SHOPS_FOOTER_BUTTON_TITLE')}
        buttonLink={'/elektrikari'}
      />
    </div>
  )
}

export default Shops
